<template>
  <div>
    <v-container class="container--fluid text-center">
      <v-card class="mt-5">
        <v-card-title class="">
          <span class="blue--text text-h6"> Inverter Ekle </span>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <div class="col-12 d-flex">
            <v-btn class="mt-2" outlined color="indigo" dense @click="createDevice">
              <v-icon left> mdi-plus </v-icon>
              Inverter Ekle
            </v-btn>
            <div class="col-2 pt-1">
              <v-select v-model="santralModel" outlined dense :items="santralList" hiden-details label="santral list" />
            </div>
          </div>
          <v-data-table :headers="devheaders" :items="devdesserts" :items-per-page="itemPerPage" class="elevation-1"
            dense>
            <template v-slot:item.action="{ item }">
              <v-icon small class="mr-2" @click="editItemOpen(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card class="col-12 mt-5 pt-0 pb-0">
          <v-card-title>
            <span class="blue--text">
              <h2>Cihaz Ekle</h2>
            </span>
          </v-card-title>
          <v-card-text>
            <inverter :inverterdata="inverterdata" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text dense outlined @click="dialog = false">
              Kapat
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { getDeviceList } from "@/api/Request/farm";
import { setInverterDelete, getInverterList } from "@/api/Request/inverter";
import { getSantralList } from "@/api/Request/santral";
import inverter from "@/views/components/Station/Inverter/Add.vue";

export default {
  components: {
    inverter,
  },
  props: [],
  data: () => ({
    devheaders: [],
    devdesserts: [],
    itemPerPage: 10,
    dialog: false,
    search: "",
    valid: true,
    lazy: true,

    edit: false,
    editdialog: false,

    prefix: "",
    santralCode: "",

    dataloggerComponentName: "",
    dataloggerModel: "",
    dataloggerList: [],
    dataloggerData: {},
    dataloggerComponentList: {},
    santralData: {},
    santralList: [],
    santralModel: null,
    inverterdata: {},
  }),
  watch: {
    dataloggerModel() {
      this.dataloggerComponentName =
        this.dataloggerComponentList[this.dataloggerModel];
    },
    santralModel() {
      this.getInverter_List();
    },
  },
  created() { },
  mounted() {
    this.dataloggerComponentList = {
      Sunguard1: "SunguardBusiness3",
      Sunguard2: "SunguardBusiness2",
      Huawei1: "HuaweiSmartLogger3000",
      SMA1: "SMA1",
      Danfass: "Danfoss1",
    };

    const storage = window.localStorage;
    const user_company = JSON.parse(
      window.atob(storage.getItem("user_company"))
    );
    this.companyCode = user_company.companyCode;
    this.prefix = user_company.prefix;

    this.getSantralList();
  },

  methods: {
    getSantralList() {
      const params1 = {
        condiniton: { prefix: this.prefix },
      };
      console.log(params1);

      getSantralList(params1).then((res) => {
        const santralList = [
          {
            text: "Seçiniz",
            value: null,
          },
        ];
        const santralData = {};
        // console.log(res);
        Object.keys(res.data.data).forEach((k) => {
          santralData[res.data.data[k].santral] = res.data.data[k];
          santralList.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santral,
          });
        });

        this.santralData = santralData;
        this.santralList = santralList;
        this.getInverter_List();
      });
    },
    deleteItem(item) {
      const param = {
        condiniton: {
          INVERTER: item.INVERTER,
          santral: item.santral,
          INVERTERNAME: item.INVERTERNAME,
          prefix: this.prefix,
        },
      };
      setInverterDelete(param).then((res) => {
        this.getInverter_List();
      });
    },
    editItemOpen(item) {
      this.dialog = true;
      this.inverterdata = item;
    },

    createDevice() {
      this.dialog = true;
      this.inverterdata = {};
    },
    santralAdd(item) {
      this.deviceModel = item.dataLoggerType;
    },

    // Datalogger listesi çek
    getDevice() {
      const params = {
        condiniton: {
          DeviceType: "Datalogger",
        },
      };
      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        Object.keys(res.data.data).forEach((i) => {
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].Model,
          });
          deviceData[res.data.data[i].Model] = res.data.data[i];
        });
        this.dataloggerList = devList;

        this.dataloggerData = deviceData;
      });
    },

    getInverter_List() {
      this.devheaders = [
        { text: "santral", value: "santral" },
        { text: "İnverter Adı", value: "INVERTERNAME" },
        { text: "INVERTER", value: "INVERTER" },
        { text: "Durum", value: "inverterStatus" },
        { text: "Marka", value: "inverterManufacture" },
        { text: "Model", value: "dataloggerDeviceModel" },
        { text: "DCPower", value: "installedDcPower" },
        { text: "", value: "action" },
      ];
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralModel,
        },
      };

      getInverterList(params).then((res) => {
        this.devdesserts = res.data.data;
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
