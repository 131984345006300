<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        Datalogger
      </v-stepper-step>

      <v-divider />

      <v-stepper-step :complete="e1 > 2" step="2">
        Cihaz tipi
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Veri
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="6">
            <v-select
              v-model="santralModel"
              outlined
              :items="santralList"
              :menu-props="{ maxHeight: '300' }"
              label="santral"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="dataloggerModel"
              outlined
              :items="dataloggerList"
              :menu-props="{ maxHeight: '300' }"
              label="Datalogger"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="inverterTypeModel"
              :items="inverterTypeList"
              :menu-props="{ maxHeight: '300' }"
              label="Cihaz Listesi"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-col>
          <v-btn color="primary" dense outlined @click="e1 = 2">
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.inverterDeviceSerial"
              label="Seri Numarası"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.INVERTERNAME"
              label="İnverter adı"
              outlined
              hide-details
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field v-model="formdata.ID" label="id (1)" outlined dense hide-details />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.INVERTER"
              label="İnverter Kodu(INVERTER01)"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.installedDcPower"
              label="DC Gücü"
              outlined
              hide-details
              dense
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.moduleQuantity"
              label="Modul Sayısı"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field v-model="formdata.SENSOR" label="SENSOR" outlined dense hide-details />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="formdata.inverterStatus"
              :items="['Active', 'Passive']"
              :menu-props="{ maxHeight: '300' }"
              label="Durum"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-col class="col-12 mt-4">
          <v-btn color="primary" dense outlined @click="e1 = 3">
            Sonraki
          </v-btn>
        </v-col>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0 ml-5 mr-5">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
              hide-details
            />
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-col cols="2">
              <v-btn color="primary" dense outlined @click="e1 = 1">
                Sonraki
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn dense outlined @click="setInverterCreate">
                Kaydet
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { getDeviceList, getDataloggerList } from "@/api/Request/farm";
import { getSantralList } from "@/api/Request/santral";
import { getInverterTypeList, setInverter } from "@/api/Request/inverter";

import helper from "@/api/helper";

export default {
  props: ["inverterdata"],
  data: () => ({
    e1: 1,
    deviceTypeModel: "INVERTER",
    inverterTypeModel: "",
    inverterTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: "",
    deviceList: [],
    deviceModel: "",
    dataloggerData: [],
    santralList: [],
    santralData: {},
    santralModel: "",

    formdata: {
      ModuleQuantity: "",
      santral: "",
      inverterDeviceSerial: "",
      INVERTERMODEL: "",
      ID: "",
      INVERTERNAME: "",
      INVERTER: "",
      installedDcPower: "",
      inverterStatus: "",
      SENSOR: ""
    }
  }),
  watch: {
    deviceTypeModel() {
      // this.getInverterTypeList();
    },
    deviceParameterModel() {},
    dataloggerModel() {
      this.getInverterTypeList();
    },
    inverterTypeModel() {
      console.log("inverterTypeModel");
      if (this.inverterTypeModel !== null) {
        console.log("getInverterType");
        this.getInverterType();
      }
    },

    santralModel() {
      if (this.santralData.length > 0) {
        this.formdata.santral = this.santralData[this.santralModel].santral;
      }

      this.getDatalogger();
    },
    inverterdata() {
      this.santralModel = "";
      this.dataloggerModel = "";
      this.inverterTypeModel = "";
      this.santralData = {};
      this.formdata = {
        ModuleQuantity: "",
        santral: "",
        inverterDeviceSerial: "",
        INVERTERMODEL: "",
        ID: "",
        INVERTERNAME: "",
        INVERTER: "",
        installedDcPower: "",
        inverterStatus: "",
        SENSOR: ""
      };
      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = "INVERTER";
      this.deviceModel = "";
      this.setInverterDataFunc();
    }
  },
  created() {
    setTimeout(() => {
      const storage = window.localStorage;
      const user_company = JSON.parse(window.atob(storage.getItem("user_company")));
      this.companyCode = user_company.companyCode;
      this.prefix = user_company.prefix;

      this.getSantralList();

      this.setInverterDataFunc();
    }, 100);
  },
  mounted() {},
  methods: {
    dialogClose() {
      this.santralModel = "";
      this.dataloggerModel = "";
      this.dialog = false;
      this.e1 = 1;
    },
    setInverterDataFunc() {
      if (this.inverterdata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: "text", value: "value" },
          { text: "key", value: "key" },
          { text: "unit", value: "unit" },
          { text: "type", value: "type" },
          { text: "formuletype", value: "formuletype" }
        ];
        this.deviceParameterList = this.inverterdata.general_measure_list;
        this.deviceParameterModel = this.inverterdata.general_measure_list;
        this.inverterTypeModel = this.inverterdata.inverterTypeDeviceId;
        this.formdata = { ...{}, ...this.inverterdata };

        this.santralModel = this.inverterdata.santral;
        this.dataloggerModel = this.inverterdata.dataloggerDeviceModel;
      }
    },
    getSantralList() {
      const params1 = {
        condiniton: { prefix: this.prefix }
      };

      getSantralList(params1).then(res => {
        const santralList = [];
        const santralData = {};

        Object.keys(res.data.data).forEach(k => {
          santralData[res.data.data[k].santral] = res.data.data[k];
          santralList.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santral
          });
        });
        this.santralData = santralData;
        this.santralList = santralList;
      });
    },
    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralModel
        }
      };

      getDataloggerList(params).then(res => {
        const devList = [];
        const deviceList = {};

        if (res.data.data.length === 0) {
          return;
        }

        Object.keys(res.data.data).forEach(i => {
          deviceList[res.data.data[i].DeviceModel] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].deviceName}`,
            value: `${res.data.data[i].DeviceModel}`
          });
        });

        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    /// / inverter tipleri listesi
    getInverterTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel
        }
      };

      getDeviceList(params).then(res => {
        const devList = [];
        console.log(res);
        /// DeviceModel
        const deviceData = {};

        Object.keys(res.data.data).forEach(i => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId
          });
        });

        // this.inverterTypeModel = deviceData;
        this.deviceData = deviceData;
        this.inverterTypeList = devList;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: "text", value: "text" },
        { text: "key", value: "key" },
        { text: "unit", value: "unit" },
        { text: "type", value: "type" },
        { text: "formuletype", value: "formuletype" }
      ];
      console.log(this.deviceData);

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }

      const params = {
        condiniton: {
          INVERTERMODEL: this.deviceData[this.inverterTypeModel].Model
        }
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then(res => {
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;
        this.itemPerPage = this.deviceParameterList.length;
      });
    },
    setInverterCreate() {
      // eslint-disable-next-line radix

      const temp = this.dataloggerData[this.dataloggerModel];

      const data = {
        INVERTERNAME: this.formdata.INVERTERNAME,
        ID: this.formdata.ID,
        INVERTER: this.formdata.INVERTER,
        installedDcPower: this.formdata.installedDcPower,
        inverterDeviceSerial: this.formdata.inverterDeviceSerial,
        SENSOR: this.formdata.SENSOR,
        moduleQuantity: this.formdata.ModuleQuantity,
        santral: this.santralModel,
        inverterStatus: this.formdata.inverterStatus,
        inverterTypeDeviceId: this.deviceData[this.inverterTypeModel].DeviceId,
        INVERTERMODEL: this.deviceData[this.inverterTypeModel].Model,
        inverterManufacture: this.deviceData[this.inverterTypeModel].DeviceManufacture,
        inverterDeviceModel: this.deviceData[this.inverterTypeModel].DeviceModel,
        general_measure_list: this.deviceParameterModel,
        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval
      };

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          deviceType: this.deviceTypeModel,
          INVERTER: data.INVERTER,
          santral: data.santral,
          santralCode: data.santralCode
        },
        data
      };

      console.log(params);

      setInverter(params).then(res => {
        // console.log(res);
      });
    }
  }
};
</script>
